import * as React from "react";
import { ValueType } from "react-select";
import { Dropdown, OptionType } from "../Dropdown";
import { ReactComponent as ChevronLeft } from "assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "assets/icons/chevron-right.svg";
import { ReactComponent as ChevronLeftDouble } from "assets/icons/chevron-left-double.svg";
import { ReactComponent as ChevronRightDouble } from "assets/icons/chevron-right-double.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { Input } from "modules/common/components/Input/Input";

interface Props {
    currentPage: number;
    next?: VoidFunction;
    prev?: VoidFunction;
    numberOfPages: number;
    pageNeighbours: number;
    pageSize: OptionType;
    onPageSizeChange: (value: ValueType<OptionType, false>) => void;
    handlepageClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    className?: string;
    first?: VoidFunction;
    last?: VoidFunction;
    handlePageSelect?: (page: number) => void;
}

const PAGE_SIZE_OPTIONS = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
];

const LEFT_DOTS = "LEFT";
const RIGHT_DOTS = "RIGHT";

const range = (start: number, stop: number, step = 1) =>
    Array(stop - start + 1)
        .fill(start)
        .map((x, y) => x + y * step);

const fetchPageNumbers = (numberOfPages: number, selectedPage: number, pageNeighbours: number) => {
    // total page numbers to show on the control
    const totalNumbers = pageNeighbours * 2 + 3;
    // totalNumbers + 2 to cover for the left(...) and right(..)
    const totalBlocks = totalNumbers + 2;

    if (numberOfPages > totalBlocks) {
        const startPage = Math.max(2, selectedPage - pageNeighbours);
        const endPage = Math.min(numberOfPages - 1, selectedPage + pageNeighbours);
        let pages = range(startPage, endPage);

        // has hidden pages to the left
        const hasLeftDots = startPage > 2;
        // has hidden pages to the right
        const hasRightDots = numberOfPages - endPage > 1;
        // number of hidden pages either to the left or to the right
        const dotOffset = totalNumbers - (pages.length + 1);

        if (hasLeftDots && !hasRightDots) {
            //case: (1) ... {6} [7] {8} (10)
            const extraPages = range(startPage - dotOffset, startPage - 1);
            pages = [LEFT_DOTS, ...extraPages, ...pages];
        } else if (!hasLeftDots && hasRightDots) {
            //case: (1) {2} [4] {5} ... (10)
            const extraPages = range(endPage + 1, endPage + dotOffset);
            pages = [...pages, ...extraPages, RIGHT_DOTS];
        } else if (hasLeftDots && hasRightDots) {
            //case: (1) ... {5} [6] {8} ... (10)
            pages = [LEFT_DOTS, ...pages, RIGHT_DOTS];
        }

        return [1, ...pages, numberOfPages];
    }

    return range(1, numberOfPages);
};

export const Pagination: React.FC<Props> = ({
    className,
    currentPage,
    numberOfPages,
    pageSize,
    pageNeighbours,
    onPageSizeChange,
    handlepageClick,
    next,
    prev,
    first,
    last,
    handlePageSelect,
}) => {
    const pages = fetchPageNumbers(numberOfPages, currentPage, pageNeighbours);
    const hasPrev = currentPage - 1 > 0;
    const hasNext = currentPage + 1 <= numberOfPages;
    const [inputPage, setInputPage] = React.useState(currentPage);

    const handlePageChange = () => {
        {
            if (inputPage > 0 && inputPage <= numberOfPages) {
                handlePageSelect && handlePageSelect(inputPage);
            }
        }
    };

    return (
        <div className={`pagination-container ${className ?? ""}`}>
            <div className="pagination-controls">
                <span className="pagination-button" onClick={first}>
                    <ChevronLeftDouble className="pagination-icon" />
                </span>
                <span
                    className={`pagination-button button-text-left ${
                        hasPrev ? "" : "pagination-button--disabled"
                    }`}
                    onClick={prev}
                >
                    <ChevronLeft className="pagination-icon" />
                </span>
                <div className="pagination-pages">
                    {pages.map((page, index) => {
                        if (page === LEFT_DOTS) {
                            return <span key={index}>...</span>;
                        }
                        if (page === RIGHT_DOTS) {
                            return <span key={index}>...</span>;
                        }
                        return (
                            <span
                                id={page}
                                onClick={handlepageClick}
                                className={`pagination-page ${
                                    currentPage === page ? "pagination-page-active" : ""
                                }`}
                                key={index}
                            >
                                {page}
                            </span>
                        );
                    })}
                </div>
                <span
                    className={`pagination-button button-text-right ${
                        hasNext ? "" : "pagination-button--disabled"
                    }`}
                    onClick={next}
                >
                    <ChevronRight className="pagination-icon" />
                </span>
                <span className="pagination-button" onClick={last}>
                    <ChevronRightDouble className="pagination-icon" />
                </span>
                <Input
                    className="pagination-input"
                    value={inputPage}
                    onChange={e => setInputPage(parseInt(e.target.value))}
                    type="number"
                    min={1}
                    max={numberOfPages}
                    size="small"
                    name="page"
                    id="page"
                    isValid={true}
                    errorMessage=""
                    onKeyPress={e => {
                        if (e.key === "Enter") {
                            handlePageChange();
                        }
                    }}
                    rightIcon={<SearchIcon className="search-icon" onClick={handlePageChange} />}
                />
            </div>
            <div className="pagination-select">
                <label htmlFor="resultsPerPage">Rezultata po stranici</label>
                <Dropdown
                    id="resultsPerPage"
                    maxMenuHeight={200}
                    onChange={onPageSizeChange}
                    isValid={true}
                    size="small"
                    errorMessage=""
                    value={pageSize}
                    options={PAGE_SIZE_OPTIONS}
                />
            </div>
        </div>
    );
};
