import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { SortingRule } from "react-table";
import { Button, FilterGroup, Input, useOutsideClick, useTitle } from "modules/common";
import { AppRoutes } from "routing";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { StatusFilter } from "modules/common/components/StatusFilter";
import { useOuterModules, OuterModuleDetailsModal, OuterModulesTable } from "modules/outerModules";
import { OuterModule, GetOuterModuleRequest } from "services";
import useDebounced from "modules/common/hooks/useDebounced";

const INPUT_DEBOUNCE_TIME = 500;

const initialRequest: GetOuterModuleRequest = {
    page: 1,
    pageSize: 10,
    search: null,
    isActive: null,
};

export const ModulesAdministration = () => {
    useTitle("Administracija modula - eSavjetnik");
    const [request, setRequest] = useState<GetOuterModuleRequest>(initialRequest);
    const [selectedModule, setSelectedModule] = useState<OuterModule>();
    const [statusFilter, setStatusFilter] = useState<number>(-1);
    const [showStatusFilter, setShowStatusFilter] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const [sortBy, setSortBy] = useState<SortingRule<object>>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const statusFilterRef = useRef<HTMLDivElement>(null);
    const search = useDebounced(searchTerm, INPUT_DEBOUNCE_TIME);
    const isStatusFilterActive = statusFilter > -1;

    const { data: outerModules, isLoading } = useOuterModules(request);

    useEffect(() => {
        setRequest(prev => ({
            ...prev,
            search: search,
            isActive: statusFilter === -1 ? null : Boolean(statusFilter),
            sortField: sortBy?.id,
            descending: sortBy?.desc,
        }));
    }, [search, statusFilter, sortBy]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSearchTerm(value);
        setRequest(prev => ({ ...prev, page: 1 }));
    };

    const handlePageSizeChange = (value: number) => {
        setRequest(prev => ({ ...prev, page: 1, pageSize: value }));
    };

    const handlePageChange = (value: number) => {
        setRequest(prev => ({ ...prev, page: value }));
    };

    const handleRowSelect = (row: OuterModule) => {
        setSelectedModule(row);
        setIsModalOpen(true);
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setStatusFilter(parseInt(value));
    };

    const handleStatusFilterClear = (_event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        setStatusFilter(-1);
    };

    const handleStatusFilterClick = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowStatusFilter(prev => !prev);
    };

    const handleStatusFilterOutsideClick = () => {
        setShowStatusFilter(false);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedModule(undefined);
    };

    useOutsideClick(statusFilterRef, handleStatusFilterOutsideClick);

    return (
        <div className="modules-administration">
            <div className="modules-administration-heading">
                <div className="modules-administration-filters">
                    <span>KORISNICI</span>
                    <FilterGroup text="">
                        <div className="mr-2" ref={statusFilterRef}>
                            <StatusFilter
                                onChange={handleStatusFilterChange}
                                onClear={handleStatusFilterClear}
                                onClick={handleStatusFilterClick}
                                value={statusFilter}
                                isActive={isStatusFilterActive}
                                showDropdown={showStatusFilter}
                            />
                        </div>
                    </FilterGroup>
                </div>
                <Link to={AppRoutes.ModuleVersionsAdministration}>
                    <Button size="xsmall" variant="primary-ghost" isDisabled={false}>
                        POVIJEST IZMJENA
                    </Button>
                </Link>
                <Button
                    size="xsmall"
                    variant="primary"
                    isDisabled={false}
                    onClick={() => setIsModalOpen(true)}
                >
                    KREIRAJ NOVI
                </Button>
                <Input
                    id="search"
                    name="search"
                    className="search"
                    type="text"
                    size="small"
                    placeholder="Pretraži"
                    isValid={true}
                    onChange={handleSearchChange}
                    errorMessage=""
                    rightIcon={<SearchIcon className="search-icon" />}
                />
            </div>

            <OuterModulesTable
                outerModules={outerModules}
                isLoading={isLoading}
                setSortBy={setSortBy}
                page={request.page}
                pageSize={request.pageSize}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePageChange}
                onRowSelect={handleRowSelect}
            />

            <OuterModuleDetailsModal
                module={selectedModule}
                totalModules={outerModules?.total ?? 0}
                isOpen={isModalOpen}
                handleClose={closeModal}
            />
        </div>
    );
};
