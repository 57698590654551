import { useTitle } from "modules/common";
import { PanelMenu } from "modules/common/components/PanelMenu";
import { useEffect, useState } from "react";
import { ReactComponent as NewWindowIcon } from "assets/icons/new-window.svg";
import { ReactComponent as ExpandFullScreenIcon } from "assets/icons/expand-fullscreen.svg";
import { ReactComponent as ShrinkFullScreenIcon } from "assets/icons/shrink-fullscreen.svg";
import { useOuterModules } from "modules/outerModules";
import { GetOuterModuleRequest } from "services";
import { sessionCache } from "modules/auth";
import { useSelector } from "react-redux";
import { RootState } from "store";

const initialRequest: GetOuterModuleRequest = {
    page: 1,
    pageSize: 1000,
    search: null,
    isActive: null,
};

export const ExternalModules = () => {
    useTitle("Vanjski moduli - eSavjetnik");
    const user = sessionCache().getSession();
    const accountList = useSelector((state: RootState) => state.accountList);
    const { data: outerModules, isLoading } = useOuterModules(initialRequest);
    const subMenuItems =
        outerModules?.items.map(item => ({ label: item.name, link: item.url })) ?? [];
    const [link, setLink] = useState<string>(subMenuItems[0]?.link ?? "");
    const [activeFullScreen, setActiveFullScreen] = useState<boolean>(false);
    const [lastLogin, setLastLogin] = useState<string | null>(null);

    // get user last login
    useEffect(() => {
        if (!user) {
            return;
        }
        const userAccount = accountList?.data?.find(acc => acc.uid === user.userId);
        if (userAccount) {
            setLastLogin(userAccount.lastLogin);
        }
    }, [accountList, user]);

    return (
        <div className="external-modules-container">
            <PanelMenu
                items={
                    outerModules?.items.map(item => ({
                        label: item.name,
                        link: item.url,
                        newModule: new Date(item.created) > new Date(lastLogin ?? ""),
                    })) ?? []
                }
                setLink={setLink}
                activeFullScreen={activeFullScreen}
            />

            {link && (
                <div className="iframe-wrapper">
                    <span className="external-module-title">
                        {outerModules?.items
                            .find(item => item.url === link)
                            ?.name.toLocaleUpperCase()}
                        <div className="external-module-title-icon-container">
                            <div
                                className="external-module-title-icon"
                                onClick={() => window.open(link, "_blank")}
                            >
                                <NewWindowIcon />
                            </div>
                            <div
                                className="external-module-title-icon"
                                onClick={() => setActiveFullScreen(!activeFullScreen)}
                            >
                                {activeFullScreen ? (
                                    <ShrinkFullScreenIcon />
                                ) : (
                                    <ExpandFullScreenIcon />
                                )}
                            </div>
                        </div>
                    </span>

                    <div className="frame-container">
                        <iframe className="agrivi-iframe" title="Vanjski moduli" src={link} />
                    </div>
                </div>
            )}
        </div>
    );
};
