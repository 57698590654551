import { xor } from "lodash";
import {
  Checkbox,
  Filter,
  FilterGroup,
  Loading,
  ToggleSwitch,
  useOutsideClick,
} from "modules/common";
import { getCounties } from "modules/dashboard";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { getExpertAreas, getMeasures } from "..";

interface Props {
  countiesFilter: string[];
  setCountiesFilter: React.Dispatch<React.SetStateAction<string[]>>;
  measuresFilter: string[];
  setMeasuresFilter: React.Dispatch<React.SetStateAction<string[]>>;
  expertAreasFilter: string[];
  setExpertAreasFilter: React.Dispatch<React.SetStateAction<string[]>>;
  isMandatoryFilter: boolean;
  setIsMandatoryFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EducationFilters: React.FC<Props> = ({
  countiesFilter,
  setCountiesFilter,
  measuresFilter,
  setMeasuresFilter,
  expertAreasFilter,
  setExpertAreasFilter,
  isMandatoryFilter,
  setIsMandatoryFilter,
}) => {
  const dispatch = useDispatch();
  const countiesFilterRef = useRef<HTMLDivElement>(null);
  const measuresFilterRef = useRef<HTMLDivElement>(null);
  const expertAreasFilterRef = useRef<HTMLDivElement>(null);

  const { counties: ReducerCounties, isLoading: loadingCounties } = useSelector(
    (state: RootState) => state.address
  );
  const { items: ReducerMeasures, isLoading: loadingMeasures } = useSelector(
    (state: RootState) => state.measures
  );
  const { items: ReducerExpertAreas, isLoading: loadingExpertAreas } =
    useSelector((state: RootState) => state.expertAreas);

  const [showCountiesFilter, setShowCountiesFilter] = useState(false);
  const [showMeasuresFilter, setShowMeasuresFilter] = useState(false);
  const [showExpertAreasFilter, setShowExpertAreasFilter] = useState(false);

  function onCountyChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedCounty = ReducerCounties?.find(
      (i) => i.valueName === event.target.value
    );

    if (!selectedCounty) {
      return;
    }

    // Create array of unique values
    const newArray = xor(countiesFilter, [selectedCounty.name]);
    setCountiesFilter(newArray);
  }

  function onMeasureChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedMeasure = ReducerMeasures?.find(
      (i) => i === event.target.value
    );

    if (!selectedMeasure) {
      return;
    }

    // Create array of unique values
    const newArray = xor(measuresFilter, [selectedMeasure]);
    setMeasuresFilter(newArray);
  }

  function onExpertAreaChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedExpertArea = ReducerExpertAreas?.find(
      (i) => i === event.target.value
    );

    if (!selectedExpertArea) {
      return;
    }

    // Create array of unique values
    const newArray = xor(expertAreasFilter, [selectedExpertArea]);
    setExpertAreasFilter(newArray);
  }

  const handleCountyFilterOutsideClick = () => {
    setShowCountiesFilter(false);
  };

  const handleMeasuresFilterOutsideClick = () => {
    setShowMeasuresFilter(false);
  };

  const handleExpertAreasFilterOutsideClick = () => {
    setShowExpertAreasFilter(false);
  };

  useOutsideClick(countiesFilterRef, handleCountyFilterOutsideClick);
  useOutsideClick(measuresFilterRef, handleMeasuresFilterOutsideClick);
  useOutsideClick(expertAreasFilterRef, handleExpertAreasFilterOutsideClick);

  const handleCountiesFilterClear = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setCountiesFilter([]);
  };

  const handleMeasuresFilterClear = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setMeasuresFilter([]);
  };

  const handleExpertAreasFilterClear = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    setExpertAreasFilter([]);
  };

  const handleCountiesFilterClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowCountiesFilter(!showCountiesFilter);

    if (!ReducerCounties.length) {
      dispatch(getCounties());
    }
  };

  const handleMeasuresFilterClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowMeasuresFilter(!showMeasuresFilter);

    if (!ReducerMeasures.length) {
      dispatch(getMeasures());
    }
  };

  const handleExpertAreasFilterClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowExpertAreasFilter(!showExpertAreasFilter);

    if (!ReducerExpertAreas.length) {
      dispatch(getExpertAreas());
    }
  };

  return (
    <FilterGroup className="mb-44 mr-32" text="Filteri">
      <div className="mr-4" ref={countiesFilterRef}>
        <Filter
          isActive={Boolean(countiesFilter.length)}
          showDropdown={showCountiesFilter}
          onClear={handleCountiesFilterClear}
          onClick={handleCountiesFilterClick}
          text="Županija"
        >
          {loadingCounties ? (
            <Loading />
          ) : (
            ReducerCounties.map((value, index) => (
              <div key={index}>
                <Checkbox
                  type="checkbox"
                  value={value.valueName}
                  checked={Boolean(countiesFilter.indexOf(value.name) > -1)}
                  onChange={onCountyChange}
                  htmlFor={value.valueName}
                  text={<div className="filter__item__text">{value.name}</div>}
                />
              </div>
            ))
          )}
        </Filter>
      </div>

      <div className="mr-4" ref={measuresFilterRef}>
        <Filter
          isActive={Boolean(measuresFilter.length)}
          showDropdown={showMeasuresFilter}
          onClear={handleMeasuresFilterClear}
          onClick={handleMeasuresFilterClick}
          text="Mjera"
        >
          {loadingMeasures ? (
            <Loading />
          ) : (
            ReducerMeasures.map((value, index) => (
              <div key={index}>
                <Checkbox
                  type="checkbox"
                  value={value}
                  checked={Boolean(measuresFilter.includes(value))}
                  onChange={onMeasureChange}
                  htmlFor={value}
                  text={<div className="filter__item__text">{value}</div>}
                />
              </div>
            ))
          )}
        </Filter>
      </div>

      <div className="mr-4" ref={expertAreasFilterRef}>
        <Filter
          isActive={Boolean(expertAreasFilter.length)}
          showDropdown={showExpertAreasFilter}
          onClear={handleExpertAreasFilterClear}
          onClick={handleExpertAreasFilterClick}
          text="Stručno podr."
        >
          {loadingExpertAreas ? (
            <Loading />
          ) : (
            ReducerExpertAreas.map((value, index) => (
              <div key={index}>
                <Checkbox
                  type="checkbox"
                  value={value}
                  checked={Boolean(expertAreasFilter.includes(value))}
                  onChange={onExpertAreaChange}
                  htmlFor={value}
                  text={<div className="filter__item__text">{value}</div>}
                />
              </div>
            ))
          )}
        </Filter>
      </div>

      <div className="f f-align-items-center">
        <ToggleSwitch
          htmlFor="active"
          value={isMandatoryFilter.toString()}
          onChange={() => setIsMandatoryFilter(!isMandatoryFilter)}
          checked={isMandatoryFilter}
          className="mr-12"
        />
        <label htmlFor="active">Obaveza</label>
      </div>
    </FilterGroup>
  );
};
