import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormGroup,
  Input,
  InputLabel,
  isEmpty,
  useTitle
} from 'modules/common';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EyeOffIcon } from 'assets/icons/eye-off.svg';
import { ReactComponent as Spinner } from 'assets/icons/spinner.svg';
import { AppRoutes } from 'routing';
import {
  AccountMessages,
  checkRedirectUrl,
  formErrorMessages,
  isValidEmail,
  sessionCache,
  signInUser,
} from 'modules/auth';

import { RootState } from 'store';
interface FormState {
  email: string;
  password: string;
}

interface Props {
  location?: Partial<ReturnType<typeof useLocation>>;
}

const Fields = {
  email: 'email',
  password: 'password',
};

export const SignIn: React.FC<Props> = ({ location }) => {
  useTitle('Prijava - eSavjetnik');
  const history = useHistory();
  const dispatch = useDispatch();
  const signIn = useSelector((state: RootState) => state.signIn);
  const profile = useSelector((state: RootState) => state.profile);
  const { isLoading: isFetchingProfile, profile: profileData } = profile;
  const registration = useSelector((state: RootState) => state.registration);
  const changePassword = useSelector(
    (state: RootState) => state.changePassword
  );
  const { successMessage: passwordSuccessMessage, changePasswordSuccess } =
    changePassword;
  const accountActivation = useSelector(
    (state: RootState) => state.activateAccount
  );
  const {
    errorMessage: activationErrorMessage,
    successMessage: activationSuccesMessage,
    activateAccountSuccess,
    activateAccountError,
  } = accountActivation;
  const { registrationSuccess, successMessage } = registration;
  const { isLoading, signInError, errorMessage } = signIn;
  const [rememberMe, setRememberMe] = useState(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [showPassword, setShowPassword] = useState(false);
  const [signInInfo, setSignInInfo] = useState<FormState>({
    email: '',
    password: '',
  });

  const session = sessionCache().getSession();

  useEffect(() => {
    if (session && profileData) {
      const redirectTo =
        location && checkRedirectUrl(location.pathname)
          ? { pathname: location.pathname, search: location.search }
          : { pathname: AppRoutes.News };

      history.push(redirectTo);
    }
  }, [history, profileData, session, location]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setSignInInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearFormError = (field: string) => {
    const filtered = Object.keys(formErrors)
      .filter((key) => key !== field)
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: formErrors[key],
        };
      }, {});
    setFormErrors({ ...filtered });
  };

  const handleShowPassword = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => setShowPassword((prev) => !prev);

  const handleEmailBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (isEmpty(value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.required,
      }));
      return;
    }

    if (!isValidEmail(value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.invalidEmail,
      }));
      return;
    }

    clearFormError(name);
  };

  const handleCheck = (_event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe((prev) => !prev);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      signInUser({
        username: signInInfo.email,
        password: signInInfo.password,
        rememberMe,
      })
    );
  };

  const buttonDisabled = false;

  return (
    <div>
      <h1 className="signin-heading mt-72">e-Savjetnik prijava</h1>
      <p className="signin-description">
        Centralno mjesto podrške razvoju poljoprivrede kroz savjete,
        predavanja, tečajeve i događanja.
      </p>
      <span className="register">
        Nemate korisnički račun?{' '}
        <Link className="register__link" to={AppRoutes.SignUp}>
          Registrirajte se
        </Link>
      </span>
      {(signInError ||
        registrationSuccess ||
        activateAccountSuccess ||
        changePasswordSuccess ||
        activateAccountError) && (
        <AccountMessages
          error={signInError || activateAccountError}
          info={
            registrationSuccess ||
            activateAccountSuccess ||
            changePasswordSuccess
          }
          message={
            errorMessage ||
            successMessage ||
            activationErrorMessage ||
            activationSuccesMessage ||
            passwordSuccessMessage
          }
        />
      )}
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <InputLabel
            labelFor={Fields.email}
            labelText="Email adresa"
            isValid={formErrors[Fields.email] ? false : true}
          />
          <Input
            name={Fields.email}
            id={Fields.email}
            type="text"
            size="large"
            placeholder="Email adresa"
            isValid={formErrors[Fields.email] ? false : true}
            errorMessage={formErrors[Fields.email]}
            onChange={handleInputChange}
            onBlur={handleEmailBlur}
          />
        </FormGroup>
        <FormGroup>
          <InputLabel
            labelFor={Fields.password}
            labelText="Lozinka"
            isValid={true}
          />
          <Input
            name={Fields.password}
            id={Fields.password}
            type={showPassword ? 'text' : 'password'}
            size="large"
            placeholder="Lozinka"
            isValid={true}
            errorMessage={formErrors[Fields.email]}
            rightIcon={
              showPassword ? (
                <EyeOffIcon onClick={handleShowPassword} />
              ) : (
                <EyeIcon onClick={handleShowPassword} />
              )
            }
            onChange={handleInputChange}
          />
        </FormGroup>
        <div className="remember-me">
          <Checkbox
            type="checkbox"
            checked={rememberMe}
            onChange={handleCheck}
            htmlFor="remember"
            text={<span>Zapamti me</span>}
          />
        </div>
        <div className="signin-box">
          {isLoading || isFetchingProfile ? (
            <div className="ml-80">
              <Spinner className="spinner" />
            </div>
          ) : (
            <Button
              type="submit"
              variant="primary"
              size="large"
              isDisabled={buttonDisabled}
            >
              <span>Prijavi se</span>
            </Button>
          )}
          <Link className="signin-box__link" to={AppRoutes.ForgotPassword}>
            Zaboravili ste lozinku?
          </Link>
        </div>
      </form>
    </div>
  );
};
