import { ReactComponent as ChevronLeftIcon } from "assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "assets/icons/chevron-right.svg";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";

const MONTHS = [
    "Siječanj",
    "Veljača",
    "Ožujak",
    "Travanj",
    "Svibanj",
    "Lipanj",
    "Srpanj",
    "Kolovoz",
    "Rujan",
    "Listopad",
    "Studeni",
    "Prosinac",
];

export function Navbar(props: any) {
    const currentDate = `${MONTHS[getMonth(props.month)].toLocaleUpperCase()}   ${getYear(
        props.month
    )}`;
    return (
        <div className={`${props.className}`}>
            <div className="back-button circle" onClick={() => props.onPreviousClick()}>
                <ChevronLeftIcon className="day-picker-control" />
            </div>
            {currentDate}
            <div className="forward-button circle" onClick={() => props.onNextClick()}>
                <ChevronRightIcon className="day-picker-control" />
            </div>
        </div>
    );
}
