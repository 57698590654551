import * as React from "react";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/checkbox-check.svg";
import { ReactComponent as CheckBoxEmpty } from "../../../../assets/icons/checkbox-empty.svg";
import { ReactComponent as CheckboxChecked } from "../../../../assets/icons/checkbox-checked.svg";

interface Props {
    checked: boolean;
    type: "checkbox" | "radio";
    htmlFor: string;
    text: React.ReactNode;
    isDisabled?: boolean;
    className?: string;
    value?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: React.FC<Props> = ({
    checked,
    type,
    className,
    htmlFor,
    value,
    text,
    isDisabled,
    onChange,
}) => {
    return (
        <div className="checkbox-container">
            <label
                className={`checkbox-label ${isDisabled ? "checkbox-label-disabled" : ""}`}
                htmlFor={htmlFor}
            >
                {text}
                {checked ? <CheckboxChecked /> : <CheckBoxEmpty className="empty" />}
            </label>
            <input
                type={type}
                checked={checked}
                onChange={onChange}
                id={htmlFor}
                value={value}
                className={`checkbox ${className ?? ""}`}
            />
        </div>
    );
};
