import { AppRoutes } from "routing";
import React from "react";
import { NavLink } from "react-router-dom";
import { Can } from "modules/auth";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { RoleType } from "modules/common/constants";
import { NavDropdown } from "modules/common";

export const Navigation: React.FC = () => {
    const { profile } = useSelector((state: RootState) => state.profile);

    return (
        <nav role="navigation" className="nav__list">
            <Can I="view" a="SuperAdminFilter">
                <NavDropdown name="Administracija">
                    <Can I="view" a="UserActivityModule">
                        <NavLink
                            to={AppRoutes.UserActivity}
                            activeClassName="nav__item--active"
                            className="nav__item"
                        >
                            Aktivnost korisnika
                        </NavLink>
                    </Can>
                    <Can I="view" a="UserAdministrationModule">
                        <NavLink
                            to={AppRoutes.UserAdministration}
                            activeClassName="nav__item--active"
                            className="nav__item"
                        >
                            Administracija korisnika
                        </NavLink>
                        <Can I="view" a="SystemAdministrationModule">
                            <NavLink
                                to={AppRoutes.SystemAdministration}
                                activeClassName="nav__item--active"
                                className="nav__item"
                            >
                                Administracija sustava
                            </NavLink>
                        </Can>
                    </Can>
                    <Can I="view" a="ModulesAdministrationModule">
                        <NavLink
                            to={AppRoutes.ModulesAdministration}
                            activeClassName="nav__item--active"
                            className="nav__item"
                        >
                            Administracija modula
                        </NavLink>
                    </Can>
                </NavDropdown>
            </Can>
            <NavLink activeClassName="nav__item--active" className="nav__item" to={AppRoutes.News}>
                Vijesti i savjeti
            </NavLink>
            <NavLink
                activeClassName="nav__item--active"
                className="nav__item"
                to={AppRoutes.Agrometeorology}
            >
                Agrometeorologija
            </NavLink>
            <Can I="view" a="AgroclimateStations">
                <NavLink
                    activeClassName="nav__item--active"
                    className="nav__item"
                    to={AppRoutes.AgroclimateStations}
                >
                    Agroklimatske stanice
                </NavLink>
            </Can>

            <Can I="view" a="PlantProtection">
                <NavLink
                    activeClassName="nav__item--active"
                    className="nav__item"
                    to={AppRoutes.PlantProtection}
                >
                    Zaštita bilja
                </NavLink>
            </Can>

            <Can I="view" a="CommunicationModule">
                <NavLink
                    activeClassName="nav__item--active"
                    className="nav__item"
                    to={AppRoutes.Communication}
                >
                    Komunikacija
                </NavLink>
            </Can>

            <Can I="view" a="Records">
                <NavLink
                    activeClassName="nav__item--active"
                    className="nav__item"
                    to={AppRoutes.Records}
                >
                    Evidencije
                </NavLink>
            </Can>

            <NavLink
                activeClassName="nav__item--active"
                className="nav__item"
                to={AppRoutes.Advisors}
            >
                {profile?.roleType?.value === RoleType.Farmer ? "Moj savjetnik" : "Savjetnici"}
            </NavLink>

            <Can I="view" a="EducationModule">
                <NavLink
                    activeClassName="nav__item--active"
                    className="nav__item"
                    to={AppRoutes.Education}
                >
                    {profile?.roleType?.value === RoleType.Farmer ? "Moje edukacije" : "Edukacije"}
                </NavLink>
            </Can>

            <Can I="view" a="ExternalModules">
                <NavLink
                    activeClassName="nav__item--active"
                    className="nav__item"
                    to={AppRoutes.ExternalModules}
                >
                    Vanjski moduli
                </NavLink>
            </Can>

            <Can I="view" a="InternalSystemsModule">
                <NavDropdown name="Interni sustavi">
                    <Can I="view" a="AdditionalModules">
                        <NavLink
                            activeClassName="nav__item--active"
                            className="nav__item"
                            to={AppRoutes.ExternalModules}
                        >
                            Dodatni moduli
                        </NavLink>
                    </Can>
                    <Can I="view" a="SEMIS">
                        <a
                            href="https://semis.savjetodavna.hr"
                            rel="noreferrer"
                            target="_blank"
                            className="nav__item"
                        >
                            SEMIS
                        </a>
                    </Can>
                    <Can I="view" a="InternalDocumentation">
                        <a
                            href="https://o365mps.sharepoint.com/_layouts/15/sharepoint.aspx"
                            rel="noreferrer"
                            target="_blank"
                            className="nav__item"
                        >
                            Sharepoint
                        </a>
                    </Can>
                    <Can I="view" a="Webtop">
                        <a
                            href="https://webtop.mps.hr/"
                            rel="noreferrer"
                            target="_blank"
                            className="nav__item"
                        >
                            Intranet
                        </a>
                    </Can>
                    <Can I="view" a="ReportProblem">
                        <a
                            href="https://podrska.mps.hr/"
                            rel="noreferrer"
                            target="_blank"
                            className="nav__item"
                        >
                            IT podrška
                        </a>
                    </Can>
                    <a
                        href="https://savjetodavna.hr/wp-login.php "
                        rel="noreferrer"
                        target="_blank"
                        className="nav__item"
                    >
                        Web autori
                    </a>
                    <Can I="view" a="PIO">
                        <a
                            href="https://pitanja.mps.hr/login"
                            rel="noreferrer"
                            target="_blank"
                            className="nav__item"
                        >
                            PIO za odgovarače
                        </a>
                    </Can>
                    <a
                        href="https://agronet.apprrr.hr/"
                        rel="noreferrer"
                        target="_blank"
                        className="nav__item"
                    >
                        Agronet
                    </a>
                    <a
                        href="https://stoka.hpa.hr/vetis"
                        rel="noreferrer"
                        target="_blank"
                        className="nav__item"
                    >
                        VETIS
                    </a>
                </NavDropdown>
            </Can>
        </nav>
    );
};
