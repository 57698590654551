import { useQuery } from "@tanstack/react-query";
import { getAuthHeaders, useAccessToken, useStandardErrorHandler } from "modules/common";
import { createService } from "services/serviceFactory";

const documentsService = createService("documents");

const getDocumentContent = async (path: string, accessToken: string, storageKey: string) => {
    const authHeaders = getAuthHeaders(accessToken);
    const response = await documentsService.get(`/v1/documents/content`, {
        ...authHeaders,
        responseType: "blob",
        params: { path: `${storageKey}/${path}` },
    });
    return response.data;
};

export const useGetDocumentContentQuery = (path: string, storageKey: string) => {
    const accessToken = useAccessToken();
    const errorHandler = useStandardErrorHandler("Greška pri dohvaćanju dokumenta");

    return useQuery({
        queryKey: ["documentContent", path, storageKey],
        queryFn: () => getDocumentContent(path, accessToken!, storageKey),
        enabled: Boolean(accessToken) && Boolean(path),
        onError: errorHandler,
    });
};
