import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ValueType } from 'react-select';
import {
  Button,
  Checkbox,
  Dropdown,
  emailRegex,
  FormGroup,
  Input,
  InputLabel,
  isEmpty,
  OptionType,
  passwordRegex,
  Tooltip,
  useTitle
} from 'modules/common';
import { AppRoutes } from 'routing';
import {
  PasswordStrength,
  PassworRequirements,
  TermsOfUseModal,
  registerUser,
  categories,
  UserTypes,
  formErrorMessages,
  AccountMessages,
  calculatePasswordStrength,
  isEightCharLong,
  isOneLowerCaseChar,
  isOneNumericChar,
  isOneUpperCaseChar,
  isValidEmail,
} from 'modules/auth';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EyeOffIcon } from 'assets/icons/eye-off.svg';
import { ReactComponent as TooltipIcon } from 'assets/icons/tooltip.svg';
import { ReactComponent as BaseValidIcon } from 'assets/icons/base-valid.svg';
import { ReactComponent as ValidIcon } from 'assets/icons/valid.svg';
import { ReactComponent as Spinner } from 'assets/icons/spinner.svg';
import { RootState } from 'store';
import { toast } from 'react-toastify';

interface FormState {
  email: string;
  password: string;
  passwordRepeat: string;
  firstName?: string;
  lastName?: string;
}

const Fields = {
  category: 'category',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  password: 'password',
  passwordRepeat: 'passwordRepeat',
  consent: 'consent',
};

export const SignUp: React.FC = () => {
  useTitle('Registracija - eSavjetnik');
  const dispatch = useDispatch();
  const registration = useSelector((state: RootState) => state.registration);
  const { registrationError, registrationSuccess, errorMessage, isLoading } =
    registration;
  const history = useHistory();
  const [showPwdReq, setShowPwdReq] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(false);
  const [lengthFulfilled, setLengthFulfilled] = useState(false);
  const [numberFulfilled, setNumberFulfilled] = useState(false);
  const [charsFulfilled, setCharsFulfilled] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [category, setCategory] = useState<OptionType | null>(null);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [userInfo, setUserInfo] = useState<FormState>({
    email: '',
    password: '',
    passwordRepeat: '',
  });

  useEffect(() => {
    if (registrationSuccess) {
      history.push({
        pathname: AppRoutes.SignIn,
        state: { registrationSuccess: true },
      });
    }
  }, [history, registrationSuccess]);

  const passwordsMatch = () => {
    return userInfo.password === userInfo.passwordRepeat;
  };
  const [showToUModal, setShowToUModal] = useState(false);
  const toggleToUModal = () => {
    setShowToUModal(!showToUModal);
  };

  const clearFormError = (field: string) => {
    const filtered = Object.keys(formErrors)
      .filter((key) => key !== field)
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: formErrors[key],
        };
      }, {});
    setFormErrors({ ...filtered });
  };

  const handleCategoryChange = (value: ValueType<OptionType, false>) => {
    setCategory(value);
  };

  const handleCategoryBlur = (_event: React.FocusEvent<HTMLElement>) => {
    if (!category) {
      setFormErrors((prev) => ({
        ...prev,
        [Fields.category]: formErrorMessages.required,
      }));
      return;
    }

    clearFormError(Fields.category);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEmailBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (isEmpty(value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.required,
      }));
      return;
    }

    if (!isValidEmail(value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.invalidEmail,
      }));
      return;
    }

    clearFormError(name);
  };

  const handleFullNameBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (isEmpty(value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.required,
      }));
      return;
    }

    clearFormError(name);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    checkPassword(value);

    setUserInfo((prev) => ({
      ...prev,
      [event.target.name]: value,
    }));
  };

  const handleCheck = (_event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted((prev) => !prev);
  };

  const handlePasswordBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setShowPwdReq(false);

    if (isEmpty(value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.required,
      }));
      setPasswordScore(0);
      setLengthFulfilled(false);
      setCharsFulfilled(false);
      setNumberFulfilled(false);
      return;
    }

    if (
      !isOneLowerCaseChar(value) ||
      !isOneUpperCaseChar(value) ||
      !isOneNumericChar(value) ||
      !isEightCharLong(value)
    ) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.invalidPassword,
      }));
      return;
    }

    if (userInfo.passwordRepeat.length && !passwordsMatch()) {
      setDoPasswordsMatch(false);
      setFormErrors((prev) => ({
        ...prev,
        [Fields.passwordRepeat]: formErrorMessages.invalidPassword,
      }));

      return;
    }

    clearFormError(name);
  };

  const checkPassword = (value: string) => {
    if (isOneLowerCaseChar(value) && isOneUpperCaseChar(value)) {
      setCharsFulfilled(true);
    } else {
      setCharsFulfilled(false);
    }

    if (isOneNumericChar(value)) {
      setNumberFulfilled(true);
    } else {
      setNumberFulfilled(false);
    }

    if (isEightCharLong(value)) {
      setLengthFulfilled(true);
    } else {
      setLengthFulfilled(false);
    }

    setPasswordScore(calculatePasswordStrength(value));
  };

  const handlePasswordRepeatBlur = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;

    if (isEmpty(value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.required,
      }));
      return;
    }

    clearFormError(name);

    if (!passwordsMatch()) {
      setDoPasswordsMatch(false);
      setFormErrors((prev) => ({
        ...prev,
        [name]: formErrorMessages.invalidPassword,
      }));

      return;
    }

    setDoPasswordsMatch(true);
    clearFormError(name);
  };

  const handleShowPassword = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => setShowPassword((prev) => !prev);

  const handleShowPassworRepeat = (
    _event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => setShowPasswordRepeat((prev) => !prev);

  const areNamesValid = () => {
    if (category && category.label === UserTypes.Citizen.label) {
      return !!(
        userInfo.firstName &&
        !!userInfo.firstName.length &&
        userInfo.lastName &&
        !!userInfo.lastName.length
      );
    }

    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!category) {
      toast.error('Niste odabrali kategoriju');
      return;
    }

    if (!emailRegex.test(userInfo.email)) {
      toast.error('Email nije u dobrom formatu');
      return;
    }

    if (!passwordRegex.test(userInfo.password)) {
      toast.error('Lozinka nije u dobrom formatu');
      return;
    }

    dispatch(
      registerUser({
        email: userInfo.email,
        password: userInfo.password,
        userType: parseInt(category?.value ?? '1'),
        metadata: {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
        },
      })
    );
  };
  const buttonDisabled =
    Object.values(userInfo).some((x) => x.length < 1) ||
    !termsAccepted ||
    !areNamesValid() ||
    Object.values(formErrors).length > 0;

  return (
    <div>
      <h1 className="signup-heading mt-48">Registracija</h1>
      <p className="signup-description">
        Odaberite kategoriju s kojom se želite registrirati u eSavjetnik sustav.
      </p>
      <span className="signin">
        Imate korisnički račun?{' '}
        <Link
          className={`signin__link ${
            registrationError || registrationSuccess ? 'mb-32' : ''
          }`}
          to={AppRoutes.SignIn}
        >
          Prijavite se
        </Link>
      </span>
      {(registrationError || registrationSuccess) && (
        <AccountMessages
          error={registrationError}
          success={registrationSuccess}
          message={errorMessage}
        />
      )}
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <InputLabel
            labelFor={Fields.category}
            labelText="Kategorija korisnika"
            isValid={formErrors[Fields.category] ? false : true}
            rightIcon={
              <Tooltip
                id="category"
                icon={<TooltipIcon />}
                tooltipText="Kategorije korisnika"
              />
            }
          />
          <Dropdown
            id={Fields.category}
            isDisabled={isLoading}
            name={Fields.category}
            maxMenuHeight={170}
            options={categories}
            placeholder="Odaberite"
            size="large"
            isValid={formErrors[Fields.category] ? false : true}
            errorMessage={formErrors[Fields.category]}
            value={category}
            onChange={handleCategoryChange}
            onBlur={handleCategoryBlur}
          />
        </FormGroup>
        <FormGroup>
          <InputLabel
            labelFor={Fields.email}
            labelText="Email adresa"
            isValid={formErrors[Fields.email] ? false : true}
          />
          <Input
            name={Fields.email}
            id={Fields.email}
            isDisabled={isLoading}
            type="text"
            size="large"
            placeholder="Email adresa"
            isValid={formErrors[Fields.email] ? false : true}
            errorMessage={formErrors[Fields.email]}
            onChange={handleInputChange}
            onBlur={handleEmailBlur}
          />
        </FormGroup>
        {category && category.label === UserTypes.Citizen.label && (
          <>
            <FormGroup>
              <InputLabel
                labelFor={Fields.firstName}
                labelText="Ime"
                isValid={formErrors[Fields.firstName] ? false : true}
              />
              <Input
                name={Fields.firstName}
                id={Fields.firstName}
                isDisabled={isLoading}
                type="text"
                size="large"
                placeholder="Ime"
                isValid={formErrors[Fields.firstName] ? false : true}
                onBlur={handleFullNameBlur}
                errorMessage={formErrors[Fields.firstName]}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <InputLabel
                labelFor={Fields.lastName}
                labelText="Prezime"
                isValid={formErrors[Fields.lastName] ? false : true}
              />
              <Input
                name={Fields.lastName}
                id={Fields.lastName}
                isDisabled={isLoading}
                type="text"
                size="large"
                placeholder="Prezime"
                isValid={formErrors[Fields.lastName] ? false : true}
                onBlur={handleFullNameBlur}
                errorMessage={formErrors[Fields.lastName]}
                onChange={handleInputChange}
              />
            </FormGroup>
          </>
        )}
        <FormGroup>
          <InputLabel
            labelFor={Fields.password}
            labelText="Lozinka"
            isValid={formErrors[Fields.password] ? false : true}
          />
          <Input
            name={Fields.password}
            isDisabled={isLoading}
            id={Fields.password}
            type={showPassword ? 'text' : 'password'}
            size="large"
            placeholder="Lozinka"
            isValid={formErrors[Fields.password] ? false : true}
            errorMessage={formErrors[Fields.password]}
            rightIcon={
              showPassword ? (
                <EyeOffIcon onClick={handleShowPassword} />
              ) : (
                <EyeIcon onClick={handleShowPassword} />
              )
            }
            onChange={handlePasswordChange}
            onFocus={() => {
              setShowPwdReq(true);
            }}
            onBlur={handlePasswordBlur}
          >
            <div className="input-password-strength">
              <PasswordStrength score={passwordScore} />
            </div>
            <PassworRequirements
              className="pwd-requirements-container"
              isActive={showPwdReq}
              lengthRequirement={lengthFulfilled}
              charRequiremenet={charsFulfilled}
              numberRequirement={numberFulfilled}
            />
          </Input>
        </FormGroup>
        <FormGroup>
          <InputLabel
            labelFor={Fields.passwordRepeat}
            labelText="Ponovite lozinku"
            isValid={formErrors[Fields.passwordRepeat] ? false : true}
          />
          <Input
            name={Fields.passwordRepeat}
            id={Fields.passwordRepeat}
            isDisabled={isLoading}
            type={showPasswordRepeat ? 'text' : 'password'}
            size="large"
            placeholder="Ponovite lozinku"
            isValid={formErrors[Fields.passwordRepeat] ? false : true}
            errorMessage={formErrors[Fields.passwordRepeat]}
            leftIcon={doPasswordsMatch ? <ValidIcon /> : <BaseValidIcon />}
            rightIcon={
              showPasswordRepeat ? (
                <EyeOffIcon onClick={handleShowPassworRepeat} />
              ) : (
                <EyeIcon onClick={handleShowPassworRepeat} />
              )
            }
            onChange={handleInputChange}
            onBlur={handlePasswordRepeatBlur}
          />
        </FormGroup>
        <div className="consent">
          <Checkbox
            type="checkbox"
            checked={termsAccepted}
            onChange={handleCheck}
            className="checkbox-label--normal"
            isDisabled={isLoading}
            htmlFor="consent"
            text={
              <div className="consent__text">
                Pristajem na{' '}
                <a onClick={toggleToUModal} className="consent__link">
                  Uvjete korištenja
                </a>{' '}
              </div>
            }
          />
        </div>
        {isLoading ? (
          <div className="ml-80">
            <Spinner className="spinner" />
          </div>
        ) : (
          <Button
            type="submit"
            variant="primary"
            size="large"
            isDisabled={buttonDisabled}
            className="mb-84"
          >
            <span>Registriraj se</span>
          </Button>
        )}
      </form>
      <TermsOfUseModal toggleModal={toggleToUModal} isVisible={showToUModal} />
    </div>
  );
};
